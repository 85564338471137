import { useState } from "react";
import { useTranslation } from "react-i18next";

import { FormControlLabel, FormLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

import UserOptionalDetails from "./UserOptionalDetails";
import UserStandardDetails from "./UserStandardDetails";

interface UserInformationFormProps {
  message: string;
  email: string;
  salutation: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  street: string;
  postcode: string;
  company: string;
  position: string;
  industry: string;
  country: string;
  mobile: string;
  dataPrivacy: boolean;
  setMessage: (message: string) => void;
  setEmail: (email: string) => void;
  setSalutation: (salutation: string) => void;
  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
  setPhoneNumber: (phoneNumber: string) => void;
  setStreet: (street: string) => void;
  setPostcode: (postcode: string) => void;
  setCompany: (company: string) => void;
  setPosition: (position: string) => void;
  setIndustry: (industry: string) => void;
  setCountry: (country: string) => void;
  setMobile: (mobile: string) => void;
  setDataPrivacy: (dataPrivacy: boolean) => void;
}

export function UserInformationForm({
  message,
  email,
  salutation,
  firstName,
  lastName,
  phoneNumber,
  street,
  postcode,
  company,
  position,
  industry,
  country,
  mobile,
  dataPrivacy,
  setMessage,
  setEmail,
  setSalutation,
  setFirstName,
  setLastName,
  setPhoneNumber,
  setStreet,
  setPostcode,
  setCompany,
  setPosition,
  setIndustry,
  setCountry,
  setMobile,
  setDataPrivacy,
}: UserInformationFormProps): JSX.Element {
  const { t } = useTranslation();

  const [optionalDetailsIsOpen, setOptionalDetailsIsOpen] = useState(false);

  return (
    <>
      <UserStandardDetails
        message={message}
        email={email}
        salutation={salutation}
        firstName={firstName}
        lastName={lastName}
        phoneNumber={phoneNumber}
        setMessage={setMessage}
        setEmail={setEmail}
        setSalutation={setSalutation}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setPhoneNumber={setPhoneNumber}
      />
      <Box>
        <Button
          onClick={() => setOptionalDetailsIsOpen(!optionalDetailsIsOpen)}
        >
          {t("regiolux:dialog.requestQuote.optionalDetails")}
        </Button>
      </Box>
      {optionalDetailsIsOpen && (
        <UserOptionalDetails
          street={street}
          postcode={postcode}
          company={company}
          position={position}
          industry={industry}
          country={country}
          mobile={mobile}
          setStreet={setStreet}
          setPostcode={setPostcode}
          setCompany={setCompany}
          setPosition={setPosition}
          setIndustry={setIndustry}
          setCountry={setCountry}
          setMobile={setMobile}
        />
      )}
      <Box sx={{ marginTop: "1rem" }}>
        <FormLabel component="legend">
          {t("regiolux:dialog.requestQuote.dataPrivacy.title")}*
        </FormLabel>
        <FormControlLabel
          control={
            <Checkbox
              checked={dataPrivacy}
              onChange={() => setDataPrivacy(!dataPrivacy)}
            />
          }
          label={
            <Typography>
              {t("regiolux:dialog.requestQuote.dataPrivacy.text.start")}{" "}
              <a href={t("regiolux:dialog.requestQuote.dataPrivacy.link.url")}>
                {t("regiolux:dialog.requestQuote.dataPrivacy.link.text")}
              </a>{" "}
              {t("regiolux:dialog.requestQuote.dataPrivacy.text.end")}
            </Typography>
          }
        />
      </Box>
    </>
  );
}
