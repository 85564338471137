import { useEffect } from "react";

import {
  CpqCore,
  CpqQueryClientProvider,
  OverridableComponentsKeys,
} from "@4pace-cpq/core";
import Button from "@mui/material/Button";
import { QueryClientProvider } from "@tanstack/react-query";

import { cpqPath, queryClient } from "./App";
import { ShoppingCartButton } from "./components/ShoppingBasket/ShoppingCartButton";
import { CustomItemGroupActionButtonsArea } from "./cpq/overrides/CustomItemGroupActionButtonsArea";
import { MinimalApp } from "./renderer/MinimalApp";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const cfgSessionId = urlParams.get("cfgSessionId");
const userSessionId = urlParams.get("userSessionId");
export const language = urlParams.get("language");

const isDevEnvironment =
  window.location.href.includes("dev") ||
  window.location.href.includes("localhost");

export function Router(): JSX.Element {
  const isInCPQ = window.location.pathname.includes(cpqPath);

  useEffect(() => {
    if (cfgSessionId) {
      window.sessionStorage.setItem("cfgSessionId", cfgSessionId);
    }
    if (userSessionId) {
      window.sessionStorage.setItem("userSessionId", userSessionId);
    }
  });

  return (
    <>
      {!isInCPQ && (
        <>
          {isDevEnvironment && (
            <Button
              //TODO[sh]: Before go live - check if all strings are still correct
              href={
                cpqPath + "/opportunities/3c5a8589-3f9c-49f3-a249-959ea151e1ae"
                // cpqPath + "/opportunities/f4ecac78-64c2-40a5-a68c-38f605ac6ed3"
              }
            >
              Go to CPQ
            </Button>
          )}
          <QueryClientProvider client={queryClient}>
            <ShoppingCartButton />
            <MinimalApp />
          </QueryClientProvider>
        </>
      )}

      {isInCPQ && (
        <>
          <Button sx={{ marginLeft: "2rem" }} href="/">
            Go Back
          </Button>
          <CpqQueryClientProvider>
            <CpqCore
              showDebugTools
              basePath={cpqPath}
              languageCode={language ? language : "de-DE"}
              componentsOverride={{
                [OverridableComponentsKeys.ItemGroupAdditionalActions]:
                  ItemGroupAdditionalActions,
                [OverridableComponentsKeys.CartViewFooter]:
                  CustomItemGroupActionButtonsArea,
              }}
            />
          </CpqQueryClientProvider>
        </>
      )}
    </>
  );
}

function ItemGroupAdditionalActions(): JSX.Element {
  return (
    <div>
      <Button variant="contained">Angebot Anfragen</Button>
      <Button variant="text">Zum Warenkorb Hinzufügen</Button>
    </div>
  );
}
