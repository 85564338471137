// generated with @7nohe/openapi-react-query-codegen@1.6.1
import { ConfigurationService } from "../requests/services.gen";
import {
  ConfiguratorBody,
  LineItemBody,
  LineItemBodyWithItemGroup,
  ProductRequestBody,
} from "../requests/types.gen";

import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";

import * as Common from "./common";

export const useConfigurationServiceGetCustomApiConfigurationPublicShoppingCartPositionsCount =
  <
    TData = Common.ConfigurationServiceGetCustomApiConfigurationPublicShoppingCartPositionsCountDefaultResponse,
    TError = unknown,
    TQueryKey extends Array<unknown> = unknown[],
  >(
    {
      userSessionId,
    }: {
      userSessionId?: string;
    } = {},
    queryKey?: TQueryKey,
    options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
  ) =>
    useQuery<TData, TError>({
      queryKey:
        Common.UseConfigurationServiceGetCustomApiConfigurationPublicShoppingCartPositionsCountKeyFn(
          { userSessionId },
          queryKey,
        ),
      queryFn: () =>
        ConfigurationService.getCustomApiConfigurationPublicShoppingCartPositionsCount(
          { userSessionId },
        ) as TData,
      ...options,
    });
export const useConfigurationServicePostCustomApiConfigurationPublicStartConfigurator =
  <
    TData = Common.ConfigurationServicePostCustomApiConfigurationPublicStartConfiguratorMutationResult,
    TError = unknown,
    TContext = unknown,
  >(
    options?: Omit<
      UseMutationOptions<
        TData,
        TError,
        {
          requestBody?: ConfiguratorBody;
        },
        TContext
      >,
      "mutationFn"
    >,
  ) =>
    useMutation<
      TData,
      TError,
      {
        requestBody?: ConfiguratorBody;
      },
      TContext
    >({
      mutationFn: ({ requestBody }) =>
        ConfigurationService.postCustomApiConfigurationPublicStartConfigurator({
          requestBody,
        }) as unknown as Promise<TData>,
      ...options,
    });
export const useConfigurationServicePostCustomApiConfigurationPublicShoppingCartConfigurations =
  <
    TData = Common.ConfigurationServicePostCustomApiConfigurationPublicShoppingCartConfigurationsMutationResult,
    TError = unknown,
    TContext = unknown,
  >(
    options?: Omit<
      UseMutationOptions<
        TData,
        TError,
        {
          requestBody?: LineItemBody;
        },
        TContext
      >,
      "mutationFn"
    >,
  ) =>
    useMutation<
      TData,
      TError,
      {
        requestBody?: LineItemBody;
      },
      TContext
    >({
      mutationFn: ({ requestBody }) =>
        ConfigurationService.postCustomApiConfigurationPublicShoppingCartConfigurations(
          { requestBody },
        ) as unknown as Promise<TData>,
      ...options,
    });
export const useConfigurationServicePostCustomApiConfigurationPublicShoppingCartShow =
  <
    TData = Common.ConfigurationServicePostCustomApiConfigurationPublicShoppingCartShowMutationResult,
    TError = unknown,
    TContext = unknown,
  >(
    options?: Omit<
      UseMutationOptions<
        TData,
        TError,
        {
          localeId?: string;
          userSessionId?: string;
        },
        TContext
      >,
      "mutationFn"
    >,
  ) =>
    useMutation<
      TData,
      TError,
      {
        localeId?: string;
        userSessionId?: string;
      },
      TContext
    >({
      mutationFn: ({ localeId, userSessionId }) =>
        ConfigurationService.postCustomApiConfigurationPublicShoppingCartShow({
          localeId,
          userSessionId,
        }) as unknown as Promise<TData>,
      ...options,
    });
export const useConfigurationServicePostCustomApiConfigurationPublicShoppingCartProducts =
  <
    TData = Common.ConfigurationServicePostCustomApiConfigurationPublicShoppingCartProductsMutationResult,
    TError = unknown,
    TContext = unknown,
  >(
    options?: Omit<
      UseMutationOptions<
        TData,
        TError,
        {
          requestBody?: ProductRequestBody;
        },
        TContext
      >,
      "mutationFn"
    >,
  ) =>
    useMutation<
      TData,
      TError,
      {
        requestBody?: ProductRequestBody;
      },
      TContext
    >({
      mutationFn: ({ requestBody }) =>
        ConfigurationService.postCustomApiConfigurationPublicShoppingCartProducts(
          { requestBody },
        ) as unknown as Promise<TData>,
      ...options,
    });
export const useConfigurationServicePostCustomApiConfigurationPublicCreateLineItemWithItemGroup =
  <
    TData = Common.ConfigurationServicePostCustomApiConfigurationPublicCreateLineItemWithItemGroupMutationResult,
    TError = unknown,
    TContext = unknown,
  >(
    options?: Omit<
      UseMutationOptions<
        TData,
        TError,
        {
          requestBody?: LineItemBodyWithItemGroup;
        },
        TContext
      >,
      "mutationFn"
    >,
  ) =>
    useMutation<
      TData,
      TError,
      {
        requestBody?: LineItemBodyWithItemGroup;
      },
      TContext
    >({
      mutationFn: ({ requestBody }) =>
        ConfigurationService.postCustomApiConfigurationPublicCreateLineItemWithItemGroup(
          { requestBody },
        ) as unknown as Promise<TData>,
      ...options,
    });
