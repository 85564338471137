import Button from "@mui/material/Button";

import { language } from "@/Router";
import {
  useConfigurationServicePostCustomApiConfigurationPublicShoppingCartShow,
  useConfigurationServicePostCustomApiConfigurationPublicStartConfigurator,
} from "@/openapi/api-cpq/queries/queries";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

export function ShoppingCartButton(): JSX.Element {
  const userSessionId =
    window.sessionStorage.getItem("userSessionId") ??
    urlParams.get("userSessionId");

  const { mutate: startConfigurator } =
    useConfigurationServicePostCustomApiConfigurationPublicStartConfigurator();

  const { mutate: showShoppingCart } =
    useConfigurationServicePostCustomApiConfigurationPublicShoppingCartShow();

  function getUserSessionId(): void {
    startConfigurator(
      {
        requestBody: {
          localeID: "de-DE",
          userToken: "",
          userSessionId: "exampleSessionID",
          cfgObjectID: "CFG_KONFIG_STANDARD",
          externalId: "exampleID",
          callbackUrl: "exampleURL",
          geolocationId: "EU",
        },
      },
      {
        onSuccess: (data) => {
          console.log({ data });
          const queryString = data.redirectUrl?.split("?")[1];
          const urlParams = new URLSearchParams(queryString);
          const userSessionId = urlParams.get("userSessionId");
          const cfgSessionId = urlParams.get("cfgSessionId");

          if (userSessionId) {
            window.sessionStorage.setItem("userSessionId", userSessionId);
          }
          if (cfgSessionId) {
            window.sessionStorage.setItem("cfgSessionId", cfgSessionId);
          }
          window.location.reload();
        },
        onError: (error) => {
          console.error("Request failed:", error);
        },
      },
    );
  }

  function redirectToShoppingCart(userSessionId: string): void {
    showShoppingCart(
      {
        userSessionId: userSessionId,
        localeId: language ? language : "de-DE",
      },
      {
        onSuccess: (data) => {
          const queryString = data.redirectUrl?.split("/cpq")[1];
          window.location.href = "/cpq" + queryString;
        },
        onError: (error) => {
          console.error("Request failed:", error);
        },
      },
    );
  }

  return (
    <>
      {userSessionId ? (
        <Button
          sx={{ marginLeft: "2rem" }}
          onClick={() => redirectToShoppingCart(userSessionId)}
        >
          Warenkorb anzeigen
        </Button>
      ) : (
        <Button sx={{ marginLeft: "2rem" }} onClick={getUserSessionId}>
          Login (set valid sessionIds)
        </Button>
      )}
    </>
  );
}
